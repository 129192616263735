import React from 'react';

import './SubFooter.scss';

const SubFooter = ({ youtube, linkedIn, twitter, copyright, logo }) => (
  <div className={'SubFooter'}>
    <div className={'SubFooter__left'}>
      <img className={'logo'} src={logo.publicURL} alt={'theodo'} />
    </div>
    <div className={'SubFooter__right'}>
      <p className={'SubFooter__right__copyright'}>{copyright}</p>
      <div className="SubFooter__right__icons">
        <a
          className={'SubFooter__right__footer_logo'}
          href={youtube}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <img src={'/img/youtube.svg'} alt={'youtube'} />
        </a>
        <a
          className={'SubFooter__right__footer_logo'}
          href={twitter}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <img src={'/img/twitter.svg'} alt={'twitter'} />
        </a>
        <a
          className={'SubFooter__right__footer_logo'}
          href={linkedIn}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <img src={'/img/linkedIn.svg'} alt={'linkedIn'} />
        </a>
      </div>
    </div>
  </div>
);
export default SubFooter;
