import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../components/Footer/Footer';
import SubFooter from '../components/SubFooter/SubFooter';
import { LangContext } from '../components/Layout';

const DescFooter = () => {
  const { language } = useContext(LangContext);
  const data = useStaticQuery(GET_FOOTER);
  return (
    <div style={{ maxWidth: '2000px', margin: '0px auto' }}>
      <Footer
        data={{
          description: data.markdownRemark.frontmatter.description,
          mail: data.markdownRemark.frontmatter.mail,
          telephone: data.markdownRemark.frontmatter.telephone,
          address: data.markdownRemark.frontmatter.address,
          copyright: data.markdownRemark.frontmatter['copyright' + language],
          newsletterTitle:
            data.markdownRemark.frontmatter['newsletterTitle_' + language],
          newsletterSubtitle:
            data.markdownRemark.frontmatter['newsletterSubtitle_' + language],
          newsletterNotifSuccess:
            data.markdownRemark.frontmatter[
              'newsletterNotifSuccess_' + language
            ],
        }}
      />
      <SubFooter
        youtube={data.markdownRemark.frontmatter.subFooter.youtube}
        linkedIn={data.markdownRemark.frontmatter.subFooter.linkedIn}
        twitter={data.markdownRemark.frontmatter.subFooter.twitter}
        copyright={
          data.markdownRemark.frontmatter.subFooter['copyright_' + language]
        }
        logo={data.markdownRemark.frontmatter.subFooter.logo}
      />
    </div>
  );
};

export default DescFooter;

const GET_FOOTER = graphql`
  query getFooterQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
      frontmatter {
        address
        telephone
        copyright_en
        copyright_fr
        mail
        subFooter {
          youtube
          linkedIn
          twitter
          copyright_en
          copyright_fr
          logo {
            publicURL
          }
        }
        newsletterTitle_en
        newsletterTitle_fr
        newsletterSubtitle_en
        newsletterSubtitle_fr
        newsletterNotifSuccess_en
        newsletterNotifSuccess_fr
      }
    }
  }
`;
