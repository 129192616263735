import React from 'react';
import './Footer.scss';

const phone = '/img/phone.svg';
function Footer(props) {
  const { data } = props;
  return (
    <footer className="footer-content">
      <div className="footer-content__middle">
        <div className="footer-content__middle__item">
          <img
            src={'/img/mail.svg'}
            alt="Email"
            className="footer-content__middle__item__icon"
          />
          <div className="footer-content__middle__item__text">{data.mail}</div>
        </div>
        <div className="footer-content__middle__item">
          <img
            src={phone}
            alt="Phone"
            className="footer-content__middle__item__icon"
          />
          <div className="footer-content__middle__item__text">
            {data.telephone}
          </div>
        </div>
        <div className="footer-content__middle__item">
          <img
            src={'/img/Address.svg'}
            alt="Address"
            className="footer-content__middle__item__icon"
          />
          <div className="footer-content__middle__item__text">
            {data.address}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
